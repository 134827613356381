/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function routes($stateProvider, nsConfigProvider) {
  $stateProvider.state('authenticated', {
    url: '',
    template: '<ui-view />',
    data: {
      permissions: {
        only: 'password',
        redirectTo: {
          default: 'login.main',
          password: /* @ngInject */ function (
            rejectedPermission,
            transitionProperties,
            $state,
            $q,
            acpAuthModel,
            acpAuthManager,
            $location,
            ACP_STORAGE_KEYS,
            nsStorage,
            nsUtil
          ) {
            var publicState = $state.get(
              'public.' + transitionProperties.toState.name
            );

            // Store that continue to route
            if (
              (rejectedPermission.message === 'password' && !publicState) ||
              rejectedPermission.message === 'session_timeout'
            ) {
              acpAuthModel.setPostLoginRedirect(
                transitionProperties.toState.name
              );
            }

            var redirect;
            switch (rejectedPermission.message) {
              case 'password':
                redirect = {
                  state: publicState ? publicState.name : 'login.main'
                };
                break;

              case 'has_auth_block':
                acpAuthManager.nextBlock();
                redirect = $q.reject(rejectedPermission.message);
                break;

              case 'session_timeout':
                redirect = {
                  state: 'login.main',
                  params: { reason: 'session-timeout' }
                };
                break;

              default:
                redirect = {
                  state: 'login.main'
                };
            }

            // keep any parameter that has been received
            if ($location.search()) {
              if (redirect.params) {
                redirect.params = nsUtil.assign(
                  redirect.params,
                  $location.search()
                );
              } else {
                redirect.params = $location.search();
              }
            }
            // some redirections are losing the parameters, so we need to keep them (they will be retrieved in postlogin)
            nsStorage.session(
              ACP_STORAGE_KEYS.ACP_AUTH_MODEL_POST_LOGIN_REDIRECT_PARAMETERS,
              redirect.params
            );
            // setting a new key in session when user tries to move to aunthenicated url without login so that after login user can redirect to that url.
            nsStorage.session(
              ACP_STORAGE_KEYS.ACP_POST_LOGIN_REDIRECT_URL,
              location.pathname
            );
            return redirect;
          }
        }
      }
    },
    resolve: {
      module: /* @ngInject */ function ($ocLazyLoad) {
        return import(
          /* webpackChunkName: "acp.section.layout" */ 'sections/layout'
        ).then($ocLazyLoad.loadModule);
      },
      layoutComponent: function () {
        return window.acp &&
          window.acp.config &&
          window.acp.config.layoutComponentOverride === 'smallbiz'
          ? 'smallbiz-layout'
          : 'layout';
      },
      $hideAppBarLogo: /* @ngInject */ function (acpFrontendConfigModel) {
        return acpFrontendConfigModel.get(
          acpFrontendConfigModel.keys.HIDE_APPBAR_LOGO,
          false
        );
      }
    }
  });
  if (nsConfigProvider.get('brand') === 'netspendExpense') {
    $stateProvider.state('defaultlayout', {
      parent: 'authenticated',
      templateUrl: 'sections/layout/templates/acp-layout.ng.html',
      url: '',
      data: {
        permissions: {
          only: 'isExpenseMgmtOnboardingCompleted',
          redirectTo: 'expenseOnboarding'
        }
      }
    });
  } else {
    $stateProvider.state('defaultlayout', {
      parent: 'authenticated',
      templateUrl: 'sections/layout/templates/acp-layout.ng.html',
      url: ''
    });
  }
  $stateProvider
    .state('root', {
      parent: 'defaultlayout',
      template: '<ui-view />',
      url: ''
    })
    .state('expenseroot', {
      parent: 'authenticated',
      url: '',
      templateUrl: 'sections/layout/templates/acp-layout-expense-public.ng.html'
    })
    .state('public', {
      abstract: true,
      url: '',
      templateUrl: 'sections/layout/templates/acp-layout-public.ng.html',
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.section.layout" */ 'sections/layout'
          ).then($ocLazyLoad.loadModule);
        }
      }
    });
}

export default routes;
